.mat-snack-bar-container {
  background-color: #000000;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  margin: 0 16px;
  font-weight: 400;

  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  align-items: center !important;
  border-radius: 10px !important;
}

.mat-snack-bar-container {
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  margin: 24px;
  max-width: 33vw;
  min-width: 0px !important;
  padding: 14px 16px;
  min-height: 48px;
  transform-origin: center;
}

.mat-simple-snackbar-action {
  color: #828df8;
}
