@import url('src/assets/styles/consts.scss');
@import url('src/assets/styles/snackbar.scss'); // overriding mat snackbar css
// syncfusion imports start here
@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-grids/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-react-spreadsheet/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../node_modules/@parspec/pixel/dist/esm/components/RichTextEditor/RichText.css';
@import '../../node_modules/@parspec/pixel/dist/esm/components/RichTextEditor/ColorPicker/ColorPicker.css';
// Ag grid default CSS
// @import 'ag-grid-community/dist/styles/ag-grid.css';
// @import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

// syncfusion imports start here
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';
@import '../../node_modules/@parspec/pixel/dist/esm/components/Table/styles.css';

// Text Editor Styles
@import '../../node_modules/@parspec/pixel/dist/esm/components/RichTextEditor/RichText.css';
@import '../../node_modules/@parspec/pixel/dist/esm/components/RichTextEditor/ColorPicker/ColorPicker.css';
//React-big-calendar css
@import 'react-big-calendar/lib/css/react-big-calendar.css';

// syncfusion imports end here
.ag-theme-alpine {
    --ag-header-height: 42px;
    --ag-header-foreground-color: #181d1f;
    --ag-header-background-color: #f1f5f9;
    --ag-header-row: 500;
    --ag-border-color: #e0e0e0;
    --ag-header-column-resize-handle-height: 100%;
    --ag-header-column-resize-handle-color: #e0e0e0;
    --ag-selected-row-background-color: #e3e3ff;
    --ag-alpine-active-color: #6467f2;
    --ag-row-hover-color: none;
    --ag-input-border-color: #6467f2;
    --ag-input-focus-border-color: #6467f2;
    --ag-range-selection-background-color: #e3e3ff;
    --ag-odd-row-background-color: unset;
    // --ag-header-cell-hover-background-color: rgb(80, 40, 140);
    // --ag-header-cell-moving-background-color: rgb(80, 40, 140);
}

.ag-grid-disable-cell {
    background-color: #f1f5f9;
}

.ag-grid-footer-cell {
    border: none;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter';
}

.btn {
    outline: none !important;
    box-shadow: none !important;
}

mat-dialog-container {
    border-radius: 0 !important;
}

.match-column-headers mat-dialog-container {
    padding: 40px;
}
.e-contextmenu-wrapper ul .e-menu-item:last-child {
    color: red;
}
// .mat-spinner-color,
// circle {
//   stroke: #ffa72b !important;
//   stroke-width: 3% !important;
// }

.mat-drawer-content {
    overflow: hidden !important;
}

.cursor-pointer {
    cursor: pointer;
}

// .mat-dialog-container {
//      padding: 0px !important;
// }

.mat-tab-link {
    min-width: 0px !important;
    left: -22px !important;
    position: relative;
}

.refresh-datasheet-dialog mat-dialog-container {
    padding: 16px 19px !important;
}

.refreshing-datasheets-dialog mat-dialog-container {
    padding: 16px 16.5px !important;
}

.match-datasheet-viewer-dialog mat-dialog-container {
    padding: 0 !important;
    border-radius: 0 !important;
}

.add-datasheet-dialog mat-dialog-container {
    border-radius: 0;
}

.reuse-datasheet-dialog .mat-dialog-container {
    padding: 0px !important;
}

.reuse-confirm-dialog .mat-dialog-container {
    padding: 16px !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    // background-color: #B7BDCF !important;
    background-color: #5048e5 !important;
}

// mat-tooltip css overrides starts here
// overriding mat-tooltip element css
.mat-tooltip,
.mat-tooltip-handset,
.mat-tooltip-show {
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px;
    color: #ffffff !important;
    background: #000000 !important;
    border-radius: 4px !important;
    padding: 8px !important;
    position: relative;
    overflow: visible !important;
}

// showing arrow to mat-tooltip if tooltip position is down the targeted element
.mat-tooltip.tooltip-with-arrow::before {
    position: absolute;
    content: '';
    display: inline-block;
    background-color: #000;
    left: 50%;
    width: 8px;
    height: 8px;
    top: 0;
    transform: translate(-50%, -50%) rotate(45deg);
    z-index: -1;
}

// showing arrow to mat-tooltip if tooltip position is right to the targeted element
.mat-tooltip-panel-right.mat-tooltip-panel .mat-tooltip.tooltip-with-arrow::before {
    left: -4px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
}

// showing arrow to mat-tooltip if tooltip position is left to the targeted element
.mat-tooltip-panel-left.mat-tooltip-panel .mat-tooltip.tooltip-with-arrow::before {
    left: auto;
    right: -4px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
}

// showing arrow to mat-tooltip if tooltip position is above the targeted element
.mat-tooltip-panel-above.mat-tooltip-panel .mat-tooltip.tooltip-with-arrow::before {
    left: 50%;
    top: 100%;
    transform: translate(-50%, -50%) rotate(45deg);
}

// mat-tooltip css overriding ends here

// mat radio color primary
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #5048e5;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: #5048e5;
}

.mat-radio-button.mat-accent .mat-radio-ripple {
    display: none;
}

// .mat-ink-bar {
//   height: 2px !important;
//   width: 65px !important;
//     margin-left: 25px !important;
// }

/* Rounded sliders */
.switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: -1px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #b7bdcf;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    width: 36px;
}

.slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 3.5px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #5048e5;
    width: 36px;
}

input:disabled + .slider {
    background-color: #dadada;
}

input:focus + .slider {
    box-shadow: 0 0 1px #5048e5;
    // width: 36px;
}

input:checked + .slider:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
    height: 15px;
    width: 15px;
}

// file upload error handle

.drag-bg-invalid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    padding: 24px;
    background: rgba(239, 68, 68, 0.2);
    border: 3px dashed #ef4444;
    box-sizing: border-box;
    border-radius: 4px;
    margin-top: 16px;
}

.normal-text {
    color: #5e667d;
}

.danger-text {
    margin-left: 0.5rem;
    color: #ef4444;
    margin-top: 1px;
}

.drag-text {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;

    /* coolGray/600 */

    color: #3b455f;
}

.hidden-input {
    width: 100%;
    height: 100%;
    z-index: 0;
    position: absolute;
    opacity: 0;
}

.ag-paging-panel {
    justify-content: center;
}

// mat-tooltip css targeting Invite Users tooltip starts here
// showing arrow to mat-tooltip if tooltip position is down the targeted element
.mat-tooltip.tooltip-with-arrow-cust::before {
    position: absolute;
    content: '';
    display: inline-block;
    background-color: #000;
    left: 50%;
    width: 8px;
    height: 8px;
    top: 0;
    transform: translate(-50%, -50%) rotate(45deg);
    z-index: -1;
}

// showing arrow to mat-tooltip if tooltip position is left to the targeted element
.mat-tooltip-panel-left.mat-tooltip-panel .mat-tooltip.tooltip-with-arrow-cust::before {
    left: auto;
    right: -4px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
}

.mat-tooltip.tooltip-width {
    max-width: unset !important;
    width: 271px;
    white-space: pre-line;
}

::-webkit-scrollbar {
    height: 6px;
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 1px;
    border-radius: 0;
    -webkit-border-radius: 1ex;
}

.textSideNavBarExpand {
    animation: textSideNavAnimation 0.5s linear 0s;
}

.row-hide {
    opacity: 0.4;
}

.e-highlightscroll {
    animation: fade-in 2s ease-in forwards 2, fade-out 4s 1s ease-out forwards 2;
}

.ag-theme-alpine .ag-header-row {
    font-weight: 500;
    font-size: 12px;
}

.ag-overlay-wrapper {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.ag-overlay-no-rows-wrapper.overlay-rows-footer {
    padding-top: 40px;
}

.ag-row-pinned {
    background-color: rgb(255, 255, 255) !important;
}

@keyframes textSideNavAnimation {
    from {
        width: 0;
    }
    to {
        width: 150px;
    }
}

//React-big-calendar css
.rbc-time-view {
    //to hide the side bar
    .rbc-label {
        display: none;
    }

    //to implement scroll in events
    .rbc-allday-cell {
        overflow: auto;
        background-color: #fff;
    }

    //to hide the time events
    .rbc-time-content {
        display: none;
    }

    //overwrite css of days header
    .rbc-header {
        background-color: #f1f5f9;
        padding: 8px !important;
    }

    //to give 100% height to all day events container
    .rbc-time-header,
    .rbc-row-content,
    .rbc-row-bg {
        height: 100%;
        overflow: auto;
    }

    .rbc-time-header-cell {
        .rbc-today {
            background-color: #e3e3ff;
        }
    }

    .rbc-today {
        background-color: #fff;
    }

    .rbc-event {
        background-color: #fff;
        color: #000;
        margin-top: 8px;
    }

    .rbc-event-content {
        overflow: visible;
    }

    ::-webkit-scrollbar {
        width: 0;
    }
}

.row-highlight {
    background-color: #fff8bd;
}

@keyframes fade-out-and-highlight {
    0% {
        background-color: #e3e3ff;
    }
    100% {
        background-color: #fff8bd;
    }
}

.e-rowhighlight-highlighscroll {
    animation: fade-in 2s ease-in forwards 2, fade-out-and-highlight 4s 1s ease-out forwards 2;
}

::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background: rgb(241, 241, 241);
}

::-webkit-scrollbar-thumb {
    background: #808080;
    border-radius: 10px;
    border: 3px solid transparent;
    background-clip: padding-box;
}
::-webkit-scrollbar-thumb:hover {
    width: 12px;
    height: 12px;
    border: 9px;
    transition: border 2s ease-in-out;
}
::-webkit-scrollbar-track {
    background: rgb(241, 241, 241);
}

a {
    text-decoration: none;
    color: #007bff;
}

a:hover {
    color: #0056b3;
    text-decoration: underline;
}

// For signup Terms and Conditions page
.signupTnCBodyContent {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #5e667d;
    height: 358px;
    // width: 590px;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    background: #ffffff;
    border: 1px solid #283453;
    box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
    padding: 14.5px 8px 0px 8px;
    .signup-tos-pre {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 20px;
        color: #000000;
        margin: 0px;
        white-space: -moz-pre-wrap; /* Mozilla, supported since 1999 */
        white-space: pre-wrap;
        word-wrap: break-word; /* IE 5.5+ */
    }
    .signup-tos-ul {
        margin-left: 16px;
    }
}

.grid-cell-centered {
    align-content: center;
}
